/* eslint-disable camelcase */
(window as any).__Zone_disable_XHR = true;
(window as any).__Zone_disable_toString = true;

(window as any).__Zone_disable_Error = true;
(window as any).__Zone_disable_bluebird = true;
(window as any).__Zone_disable_defineProperty = true;
(window as any).__Zone_disable_registerElement = true;
(window as any).__Zone_disable_queueMicrotask = true;
(window as any).__Zone_disable_on_property = true;
(window as any).__Zone_disable_requestAnimationFrame = true;
(window as any).__Zone_disable_blocking = true;
(window as any).__Zone_disable_MutationObserver = true;
(window as any).__Zone_disable_IntersectionObserver = true;
(window as any).__Zone_disable_FileReader = true;
(window as any).__Zone_disable_customElements = true;
(window as any).__Zone_disable_canvas = true;
(window as any).__Zone_disable_IE_check = true;
(window as any).__Zone_enable_cross_context_check = true;
(window as any).__Zone_disable_geolocation = true;
(window as any).__Zone_disable_PromiseRejectionEvent = true;
(window as any).__Zone_disable_mediaQuery = true;
(window as any).__Zone_disable_notification = true;
(window as any).__Zone_disable_MessagePort = true;

(window as any).__zone_symbol__DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION = true;

(window as any).__zone_symbol__UNPATCHED_EVENTS = [
  // mouse and pointer events
  'mousemove', 'mouseout', 'mouseover', 'mousewheel', 'pointerover', 'resize',
  // XHR related events
  'loadstart', 'progress', 'abort', 'error', 'load', 'timeout', 'loadend', 'readystatechange',
  // socket events
  'open', 'error', 'close', 'message',
  // storage event
  'storage'
];

(window as any).__zone_symbol__PASSIVE_EVENTS = [
  'wheel'
];

(window as any).__Zone_ignore_on_properties = [
  {
    target: window,
    ignoreProperties: [
      'search',
      'appinstalled',
      'beforeinstallprompt',
      'beforexrselect',
      'abort',
      'beforeinput',
      'blur',
      'cancel',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'close',
      'contextlost',
      'contextmenu',
      'contextrestored',
      'cuechange',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'emptied',
      'ended',
      'error',
      'focus',
      'formdata',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup',
      'mousewheel',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'reset',
      'resize',
      'scroll',
      'securitypolicyviolation',
      'seeked',
      'seeking',
      'select',
      'slotchange',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'volumechange',
      'waiting',
      'webkitanimationend',
      'webkitanimationiteration',
      'webkitanimationstart',
      'webkittransitionend',
      'wheel',
      'auxclick',
      'gotpointercapture',
      'lostpointercapture',
      'pointerdown',
      'pointermove',
      'pointerrawupdate',
      'pointerup',
      'pointercancel',
      'pointerover',
      'pointerout',
      'pointerenter',
      'pointerleave',
      'selectstart',
      'selectionchange',
      'animationend',
      'animationiteration',
      'animationstart',
      'transitionrun',
      'transitionstart',
      'transitionend',
      'transitioncancel',
      'afterprint',
      'beforeprint',
      'beforeunload',
      'hashchange',
      'languagechange',
      'message',
      'messageerror',
      'offline',
      'online',
      'pagehide',
      'pageshow',
      'popstate',
      'rejectionhandled',
      'storage',
      'unhandledrejection',
      'unload',
      'devicemotion',
      'deviceorientation',
      'deviceorientationabsolute',
      'beforematch',
      'beforetoggle',
      'contentvisibilityautostatechange',
      'scrollend'
    ]
  },
  {
    target: Document.prototype,
    ignoreProperties: [
      'readystatechange',
      'pointerlockchange',
      'pointerlockerror',
      'beforecopy',
      'beforecut',
      'beforepaste',
      'freeze',
      'prerenderingchange',
      'resume',
      'search',
      'visibilitychange',
      'fullscreenchange',
      'fullscreenerror',
      'webkitfullscreenchange',
      'webkitfullscreenerror',
      'beforexrselect',
      'abort',
      'beforeinput',
      'blur',
      'cancel',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'close',
      'contextlost',
      'contextmenu',
      'contextrestored',
      'cuechange',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'emptied',
      'ended',
      'error',
      'focus',
      'formdata',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup',
      'mousewheel',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'reset',
      'resize',
      'scroll',
      'securitypolicyviolation',
      'seeked',
      'seeking',
      'select',
      'slotchange',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'volumechange',
      'waiting',
      'webkitanimationend',
      'webkitanimationiteration',
      'webkitanimationstart',
      'webkittransitionend',
      'wheel',
      'auxclick',
      'gotpointercapture',
      'lostpointercapture',
      'pointerdown',
      'pointermove',
      'pointerrawupdate',
      'pointerup',
      'pointercancel',
      'pointerover',
      'pointerout',
      'pointerenter',
      'pointerleave',
      'selectstart',
      'selectionchange',
      'animationend',
      'animationiteration',
      'animationstart',
      'transitionrun',
      'transitionstart',
      'transitionend',
      'transitioncancel',
      'copy',
      'cut',
      'paste',
      'beforematch',
      'beforetoggle',
      'contentvisibilityautostatechange',
      'scrollend'
    ]
  },
  {
    target: SVGElement.prototype,
    ignoreProperties: [
      'beforexrselect',
      'abort',
      'beforeinput',
      'blur',
      'cancel',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'close',
      'contextlost',
      'contextmenu',
      'contextrestored',
      'cuechange',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'emptied',
      'ended',
      'error',
      'focus',
      'formdata',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup',
      'mousewheel',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'reset',
      'resize',
      'scroll',
      'securitypolicyviolation',
      'seeked',
      'seeking',
      'select',
      'slotchange',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'volumechange',
      'waiting',
      'webkitanimationend',
      'webkitanimationiteration',
      'webkitanimationstart',
      'webkittransitionend',
      'wheel',
      'auxclick',
      'gotpointercapture',
      'lostpointercapture',
      'pointerdown',
      'pointermove',
      'pointerrawupdate',
      'pointerup',
      'pointercancel',
      'pointerover',
      'pointerout',
      'pointerenter',
      'pointerleave',
      'selectstart',
      'selectionchange',
      'animationend',
      'animationiteration',
      'animationstart',
      'transitionrun',
      'transitionstart',
      'transitionend',
      'transitioncancel',
      'copy',
      'cut',
      'paste',
      'contentvisibilityautostatechange',
      'scrollend',
      'beforematch',
      'beforetoggle'
    ]
  },
  {
    target: Element.prototype,
    ignoreProperties: [
      'beforecopy',
      'beforecut',
      'beforepaste',
      'search',
      'fullscreenchange',
      'fullscreenerror',
      'webkitfullscreenchange',
      'webkitfullscreenerror'
    ]
  },
  {
    target: HTMLElement.prototype,
    ignoreProperties: [
      'beforexrselect',
      'abort',
      'beforeinput',
      'blur',
      'cancel',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'close',
      'contextlost',
      'contextmenu',
      'contextrestored',
      'cuechange',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'emptied',
      'ended',
      'error',
      'focus',
      'formdata',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup',
      'mousewheel',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'reset',
      'resize',
      'scroll',
      'securitypolicyviolation',
      'seeked',
      'seeking',
      'select',
      'slotchange',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'volumechange',
      'waiting',
      'webkitanimationend',
      'webkitanimationiteration',
      'webkitanimationstart',
      'webkittransitionend',
      'wheel',
      'auxclick',
      'gotpointercapture',
      'lostpointercapture',
      'pointerdown',
      'pointermove',
      'pointerrawupdate',
      'pointerup',
      'pointercancel',
      'pointerover',
      'pointerout',
      'pointerenter',
      'pointerleave',
      'selectstart',
      'selectionchange',
      'animationend',
      'animationiteration',
      'animationstart',
      'transitionrun',
      'transitionstart',
      'transitionend',
      'transitioncancel',
      'copy',
      'cut',
      'paste',
      'contentvisibilityautostatechange',
      'scrollend',
      'beforetoggle',
      'beforematch'
    ]
  },
  {
    target: HTMLBodyElement.prototype,
    ignoreProperties: [
      'blur',
      'error',
      'focus',
      'load',
      'resize',
      'scroll',
      'afterprint',
      'beforeprint',
      'beforeunload',
      'hashchange',
      'languagechange',
      'message',
      'messageerror',
      'offline',
      'online',
      'pagehide',
      'pageshow',
      'popstate',
      'rejectionhandled',
      'storage',
      'unhandledrejection',
      'unload'
    ]
  },
  {
    target: HTMLMediaElement.prototype,
    ignoreProperties: ['encrypted', 'waitingforkey']
  },
  {
    target: HTMLFrameSetElement.prototype,
    ignoreProperties: [
      'blur',
      'error',
      'focus',
      'load',
      'resize',
      'scroll',
      'afterprint',
      'beforeprint',
      'beforeunload',
      'hashchange',
      'languagechange',
      'message',
      'messageerror',
      'offline',
      'online',
      'pagehide',
      'pageshow',
      'popstate',
      'rejectionhandled',
      'storage',
      'unhandledrejection',
      'unload'
    ]
  },
  {
    target: Worker.prototype,
    ignoreProperties: ['message', 'error']
  },
  {
    target: XMLHttpRequest.prototype,
    ignoreProperties: ['readystatechange']
  },
  {
    target: XMLHttpRequestEventTarget.prototype,
    ignoreProperties: ['loadstart', 'progress', 'abort', 'error', 'load', 'timeout', 'loadend']
  },
  {
    target: IDBRequest.prototype,
    ignoreProperties: ['success', 'error']
  },
  {
    target: IDBOpenDBRequest.prototype,
    ignoreProperties: ['blocked', 'upgradeneeded']
  },
  {
    target: IDBDatabase.prototype,
    ignoreProperties: ['abort', 'close', 'error', 'versionchange']
  },
  {
    target: IDBTransaction.prototype,
    ignoreProperties: ['abort', 'complete', 'error']
  },
  {
    target: WebSocket.prototype,
    ignoreProperties: ['open', 'error', 'close', 'message']
  }
];
